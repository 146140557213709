var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CRow',[_c('CCol',[_c('BaseTable',{staticClass:"table-custom table-custom-link",attrs:{"is-loading":_vm.isLoading,"pagination":{
					pages: _vm.list.meta.lastPage,
					activePage: _vm.list.meta.currentPage
				},"fields":_vm.FEED_TABLE_FIELDS,"items":_vm.feedTableData,"clickable-rows":"","link-to":"ProductFeedEdit"},on:{"onPaginationClick":_vm.handlePageChange},scopedSlots:_vm._u([{key:"no-items-view",fn:function(){return [_c('div',{staticClass:"empty-table-element"},[_c('p',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.$t("global.searchNotFound", { field: "feeds" }))+" ")])])]},proxy:true},{key:"status",fn:function(ref){
				var item = ref.item;
return [_c('CBadge',{staticClass:"badge-status",attrs:{"color":item === true ? 'active' : 'inactive'}},[_vm._v(" "+_vm._s(item === true ? 'Active' : 'Inactive')+" ")])]}},{key:"duplicateId",fn:function(ref){
				var item = ref.item;
return [_c('CLink',{attrs:{"variant":"ghost","color":"secondary","to":{ name: 'ProductFeedCreate', query: { ref: item } }}},[_vm._v(" Duplicate ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }