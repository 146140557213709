<template>
	<div>
		<CRow>
			<CCol>
				<BaseTable
					:is-loading="isLoading"
					:pagination="{
						pages: list.meta.lastPage,
						activePage: list.meta.currentPage
					}"
					:fields="FEED_TABLE_FIELDS"
					:items="feedTableData"
					clickable-rows
					link-to="ProductFeedEdit"
					class="table-custom table-custom-link"
					@onPaginationClick="handlePageChange"
				>
					<template #no-items-view>
						<div class="empty-table-element">
							<p class="subtitle">
								{{ $t("global.searchNotFound", { field: "feeds" }) }}
							</p>
						</div>
					</template>
					<template #status="{item}">
						<CBadge class="badge-status" :color="item === true ? 'active' : 'inactive'">
							{{ item === true ? 'Active' : 'Inactive' }}
						</CBadge>
					</template>
					<template #duplicateId="{item}">
						<CLink
							variant="ghost"
							color="secondary"
							:to="{ name: 'ProductFeedCreate', query: { ref: item } }"
						>
							Duplicate
						</CLink>
					</template>
				</BaseTable>
			</CCol>
		</CRow>
	</div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { cleanObject, cloneDeep, randomSearchString } from '../assets/js/helpers';

const FEED_TABLE_FIELDS = [
	{ key: 'id', label: 'ID', class: 'col-id', cellClass: 'cell-id' },
	{ key: 'partner', label: 'Partner', class: 'col-partner' },
	{ key: 'status', label: 'Status', class: 'col-status' },
	{ key: 'regenerateTimeText', label: 'Regenerate time', class: 'col-regenerate-time' },
	{ key: 'fullUrl', label: 'URL', class: 'col-url', cellClass: 'cell-url' },
	{ key: 'lastUpdated', label: 'Last updated', class: 'col-last-updated' },
	{ key: 'duplicateId', label: '', class: 'col-duplicate-id' },
];

export default {
	name: 'ProductFeedList',

	data() {
		return {
			FEED_TABLE_FIELDS,
			isLoading: false,
			queryParams: {
				page: Number(this.$route.query.page) || null,
				per_page: Number(this.$route.query.per_page) || null,
				r: randomSearchString(),
			},
		};
	},

	computed: {
		...mapState('feeds', {
			list: 'list',
		}),
		...mapGetters({
			feedTableData: 'feeds/feedTableData',
		}),
	},

	async created() {
		this.isLoading = true;
		await this.getFeedList(this.queryParams);
		this.isLoading = false;
	},

	methods: {
		...mapActions({
			getFeedList: 'feeds/getFeedList',
		}),
		updateUrlParams() {
			const query = cleanObject(cloneDeep(this.queryParams));
			this.$router.push({ query }).catch(() => {});
		},
		handlePageChange(page) {
			this.queryParams.page = page;
			this.updateUrlParams();
		},
	},
};
</script>

<style lang="scss" scoped>
	::v-deep .table-custom {
		.col-url {
			min-width: rem(250);
		}

		.col-status,
		.col-id {
			width: rem(120);
		}

		.col-last-updated,
		.col-regenerate-time {
			width: rem(250);
		}

		.cell-url {
			color: $color-hypertext;
			word-break: break-all;
		}
	}
</style>
